(function(){

	var botaoVoltar = $('[data-voltar]');

	botaoVoltar.each(function(){

		$(this).click(function(){

			javascript:history.go(-1);

		});

	});

})();