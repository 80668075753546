(function(){

	var recebedor = $('#topo');

	var ativador = $('#toggleMenu, .bg-menu');

	ativador.click(function(){

		recebedor.toggleClass('ativado');

	});

})();